import Vue from "vue";
import AppDropDownList from "./AppDropDownList.vue";
export default Vue.extend({
    components: {
        "app-dropdownlist": AppDropDownList
    },
    props: {
        id: {
            type: String,
            required: true
        },
        enabled: {
            type: Boolean,
            required: false,
            default: true
        },
        placeholder: {
            type: String,
            required: false
        },
        value: {
            type: [String, Number],
            required: false
        },
        cssClass: {
            type: String,
            required: false,
            default: ""
        }
    },
    data() {
        return {
            source: [
                { text: this.$t("customers.customer"), value: 1 },
                { text: this.$t("employees.employee"), value: 2 }
            ],
            fields: { text: "text", value: "value" }
        };
    },
    methods: {
        emitValue(value) {
            this.$emit("input", value);
        }
    }
});
