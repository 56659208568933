import Vue from "vue";
import TitleMixin from "@/mixins/title";
import SpinnerMixin from "@/mixins/spinner";
import AppDropDownListOwnerType from "@/components/Inputs/AppDropDownListOwnerType.vue";
export default Vue.extend({
    components: {
        "app-dropdownlist-owner-type": AppDropDownListOwnerType
    },
    mixins: [TitleMixin, SpinnerMixin],
    data() {
        return {
            title: this.$t("attachmentTypes.create.title"),
            id: this.$route.params.id,
            attachmentType: {},
            validationOptions: {
                rules: {
                    name: {
                        required: true,
                        rangeLength: [3, 100]
                    },
                    description: {
                        maxLength: 255
                    },
                    ownerType: {
                        required: true,
                    }
                }
            }
        };
    },
    methods: {
        create() {
            return this.$store.dispatch("attachmentType/create", {
                attachmentType: this.attachmentType
            });
        },
        createSuccess() {
            this.$toast.showSuccessToast(this.$t("attachmentTypes.create.toastSuccessTitle"), this.$t("attachmentTypes.create.toastSuccessContent", {
                name: this.attachmentType.name
            }));
            return this.$router.push("/attachments/types");
        },
        cancel() {
            return this.$router.push("/attachments/types");
        }
    }
});
